import React, { Component } from "react";

import {
  AuthManagerQueryResult,
  LogoutUserMutationFn,
  AuthManager_ViewerFragment,
  AuthManager_PublisherFragment,
  AuthPublisherQueryResult,
} from "../../generated/graphql";
import { ConnectivityContext } from "../connectivity-monitor";
import { noop } from "../../util/noop";
import { WithRouterProps, withRouter } from "./with-router";

export interface AuthContextValue {
  isLoading: boolean;
  isLoggedIn: boolean;
  refresh: () => Promise<any>;
  logout: () => Promise<any>;
  viewer: AuthManager_ViewerFragment | null;
  publisher: AuthManager_PublisherFragment | null;
}

interface Props extends WithRouterProps {
  queryResult: AuthManagerQueryResult;
  publisherQueryResult: AuthPublisherQueryResult;
  children: React.ReactNode;
  logoutUserMutation: LogoutUserMutationFn;
}

export const AuthContext = React.createContext<AuthContextValue>({
  isLoading: true,
  isLoggedIn: false,
  refresh: noop,
  logout: noop,
  viewer: null,
  publisher: null,
});

class AuthManager extends Component<Props> {
  render() {
    const { queryResult, logoutUserMutation, publisherQueryResult } = this.props;
    const contextValue: AuthContextValue = {
      isLoading: queryResult.loading,
      isLoggedIn: false,
      refresh: queryResult.refetch,
      logout: async () => {
        await logoutUserMutation();
        await queryResult.refetch();
      },
      viewer: null,
      publisher: null,
    };

    if (!queryResult.loading) {
      const { data, error } = queryResult;
      const viewer = data?.viewer ?? null;
      if (data) {
        contextValue.isLoggedIn = !error && !!viewer?.user;
        contextValue.viewer = viewer;
      }
    }

    if (!publisherQueryResult.loading) {
      const { data } = publisherQueryResult;
      contextValue.publisher = data?.publisher ?? null;
    }
    return (
      <AuthContext.Provider value={contextValue}>
        <ConnectivityContext.Consumer>
          {(connectivityContext) => this.renderContent(contextValue, connectivityContext.isConnected)}
        </ConnectivityContext.Consumer>
      </AuthContext.Provider>
    );
  }

  renderContent(contextValue: AuthContextValue, isConnected: boolean) {
    const { children } = this.props;
    if (!contextValue) {
      return null;
    }
    if (!isConnected) {
      // If API connectivity goes down, prevent redirects for an improved user experience.
      return null;
    }
    return children;
  }
}

export default withRouter(AuthManager);
