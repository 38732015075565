export const LOADER_TYPE = {
  content: "content",
  table: "table",
  fullView: "fullView",
};
export const TOAST_POSITION = "top-right";
export const TOAST_DURATION = 3000;
export const PAGE_LIMIT = 50;
export const DATE_FORMAT = "yyyy-MM-dd";
export const DATE_TEXT_FORMAT = "MMM d";
export const DATE_TIME_FORMAT = "ff";
export const MMM_D_YYYY = "MMM d, yyyy";
export const MMM_D_YYYY_H_MM_A = "MMM d, yyyy h:mm a";
export const DATE_PICKER_FORMAT = "MMM d, yyyy";

export const TASK_TYPES = {
  PROPOSAL_REVIEW: "website-proposal-review",
};

export const MAIN_NAV_HEIGHT = "64px";

export const APP_NAME = "publisher";
