import { lazy, Suspense } from "react";
import { Route, Routes as RouterRoutes } from "react-router-dom";

import { DefaultRoute } from "./_default";
import { Loader } from "../components/loader";
import { RouteAuthorization } from "../components/route-authorization";
import { GoogleOAuthProvider } from "@react-oauth/google";
//const Signup = lazy(() => import("./signup"));
const LogIn = lazy(() => import("./login"));
const Sso = lazy(() => import("./sso"));
const RequestPasswordReset = lazy(() => import("./request-password-reset"));
const ResetPassword = lazy(() => import("./reset-password"));
const NotFound = lazy(() => import("./not-found"));
const Sites = lazy(() => import("./sites"));
const SiteDetails = lazy(() => import("./site-details"));
const Users = lazy(() => import("./users"));
const ProfileRoute = lazy(() => import("./profile"));
const Account = lazy(() => import("./account"));
const Report = lazy(() => import("./report"));
const Task = lazy(() => import("./task"));

export function Routes() {
  return (
    <Suspense fallback={<Loader />}>
      <RouterRoutes>
        <Route path="/" element={<DefaultRoute />} />
        <Route
          path="/login"
          element={
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID ?? ""}>
              <LogIn />
            </GoogleOAuthProvider>
          }
        />
        <Route path="/sso/:code/*" element={<Sso />} />
        <Route path="/request-password-reset" element={<RequestPasswordReset />} />
        <Route path="/reset-password/:id" element={<ResetPassword />} />
        <Route
          path="/sites"
          element={
            <RouteAuthorization>
              <Sites />
            </RouteAuthorization>
          }
        />
        <Route
          path="/sites/:id"
          element={
            <RouteAuthorization>
              <SiteDetails />
            </RouteAuthorization>
          }
        />
        <Route
          path="/users"
          element={
            <RouteAuthorization>
              <Users />
            </RouteAuthorization>
          }
        />
        <Route
          path="/profile"
          element={
            <RouteAuthorization>
              <ProfileRoute />
            </RouteAuthorization>
          }
        />
        <Route
          path="/account"
          element={
            <RouteAuthorization>
              <Account />
            </RouteAuthorization>
          }
        />
        <Route
          path="/report"
          element={
            <RouteAuthorization>
              <Report />
            </RouteAuthorization>
          }
        />
        <Route
          path="/tasks/:id"
          element={
            <RouteAuthorization>
              <Task />
            </RouteAuthorization>
          }
        />
        <Route path="*" element={<NotFound />} />
      </RouterRoutes>
    </Suspense>
  );
}
