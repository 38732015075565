import React from "react";
import AuthManager from "./auth-manager";
import { useLogoutUserMutation, useAuthManagerQuery, useAuthPublisherQuery } from "../../generated/graphql";

interface Props {
  children: React.ReactNode;
}

export function AuthManagerContainer(props: Props) {
  const [logoutUserMutation] = useLogoutUserMutation();
  const queryResult = useAuthManagerQuery({ fetchPolicy: "network-only" });
  const publisherQueryResult = useAuthPublisherQuery({
    fetchPolicy: "network-only",
    variables: {
      id: queryResult.data?.viewer.accessiblePublisherIds?.[0] ?? 0,
    },
    skip: !queryResult.data?.viewer.accessiblePublisherIds.length,
  });

  return (
    <AuthManager
      queryResult={queryResult}
      publisherQueryResult={publisherQueryResult}
      logoutUserMutation={logoutUserMutation}
    >
      {props.children}
    </AuthManager>
  );
}
