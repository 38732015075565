const leapYear = (year: number): boolean => {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
};

export const validateTime = (time: string): boolean => {
  const TIME_REGEX =
    /^([01][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])(\.\d{1,})?(([Z])|([+|-]([01][0-9]|2[0-3]):[0-5][0-9]))$/;
  return TIME_REGEX.test(time);
};

export const validateDate = (dateString: string): boolean => {
  const RFC_3339_REGEX = /^(\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01]))$/;

  if (!RFC_3339_REGEX.test(dateString)) {
    return false;
  }

  const year = Number(dateString.substr(0, 4));
  const month = Number(dateString.substr(5, 2));
  const day = Number(dateString.substr(8, 2));

  switch (month) {
    case 2: // February
      if (leapYear(year) && day > 29) {
        return false;
      } else if (!leapYear(year) && day > 28) {
        return false;
      }
      return true;
    case 4: // April
    case 6: // June
    case 9: // September
    case 11: // November
      if (day > 30) {
        return false;
      }
      break;
  }

  return true;
};

export const validateDateTime = (dateTimeString: string): boolean => {
  const RFC_3339_REGEX =
    /^(\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])T([01][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9]|60))(\.\d{1,})?(([Z])|([+|-]([01][0-9]|2[0-3]):[0-5][0-9]))$/;

  // Validate the structure of the date-string
  if (!RFC_3339_REGEX.test(dateTimeString)) {
    return false;
  }

  // Check if it is a correct date using the javascript Date parse() method.
  const time = Date.parse(dateTimeString);
  if (time !== time) {
    // eslint-disable-line
    return false;
  }
  // Split the date-time-string up into the string-date and time-string part.
  // and check whether these parts are RFC 3339 compliant.
  const index = dateTimeString.indexOf("T");
  const dateString = dateTimeString.substr(0, index);
  const timeString = dateTimeString.substr(index + 1);
  return validateDate(dateString) && validateTime(timeString);
};

export function convertObjectIsoToDate(obj: any): any {
  if (typeof obj === "string" && validateDateTime(obj)) {
    obj = new Date(obj);
  } else if (Array.isArray(obj)) {
    // PARSE ARRAY
    obj = obj.map((value) => {
      let val = value;
      if (typeof value === "string" && validateDateTime(value)) {
        val = new Date(value);
      } else if (Array.isArray(val) || typeof val === "object") {
        val = convertObjectIsoToDate(value);
      }
      return val;
    });
  } else if (typeof obj === "object") {
    // PARSE OBJECT
    for (const key in obj) {
      const value = obj[key];
      if (typeof value === "string" && validateDateTime(value)) {
        obj[key] = new Date(value);
      } else if (Array.isArray(value) || typeof value === "object") {
        obj[key] = convertObjectIsoToDate(value);
      }
    }
  }

  return obj;
}
