import React, { useContext } from "react";
import { ConnectivityContext } from "./connectivity-monitor";
import { ApolloClient, InMemoryCache, HttpLink, ApolloProvider, ServerError, ApolloLink } from "@apollo/client";
import { onError } from "@apollo/link-error";
import { convertObjectIsoToDate } from "../util/iso-helpers/iso-validator";

interface ClientOptions {
  onNetworkError: () => any;
}

function getNetworkErrorHandlerLink(onNetworkError: () => any) {
  return onError(({ networkError }) => {
    if (networkError) {
      if (!(networkError as ServerError).statusCode) {
        onNetworkError();
      }
    }
  });
}

const convertDateLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    if (response.data) {
      response.data = convertObjectIsoToDate(response.data);
    }
    return response;
  });
});

export const getClient = (options: ClientOptions) => {
  const httpLink = new HttpLink({
    uri: `${process.env.REACT_APP_API_BASE}/internal/graphql`,
    credentials: "include",
  });
  return new ApolloClient({
    cache: new InMemoryCache(),
    link: getNetworkErrorHandlerLink(options.onNetworkError).concat(convertDateLink).concat(httpLink),
  });
};

export const client = getClient({
  onNetworkError: () => {
    // no-op
  },
});

interface Props {
  children: React.ReactNode;
}

export const CustomApolloProvider = (props: Props) => {
  const { children } = props;
  const connectivityContext = useContext(ConnectivityContext);
  const onNetworkError = () => {
    connectivityContext.testApiConnectivity();
  };
  return <ApolloProvider client={getClient({ onNetworkError })}>{children}</ApolloProvider>;
};
