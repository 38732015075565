import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../../core/auth-manager";
import { Loader } from "../../components/loader";

export function DefaultRoute() {
  const authContext = useContext(AuthContext);
  if (authContext.isLoading) {
    return <Loader type="fullView" />;
  }

  if (!authContext.isLoggedIn || !authContext.viewer) {
    return <Navigate to="/login" />;
  }

  return <Navigate to="/report" />;
}
