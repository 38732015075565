import { BrowserRouter as Router } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ChakraProvider } from "@chakra-ui/react";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";

import { CustomApolloProvider } from "./core/apollo-provider";
import AuthManager from "./core/auth-manager";
import { ConnectivityMonitor } from "./core/connectivity-monitor";
import customTheme from "./core/custom-theme";
import { Routes } from "./routes";

// TODO: Do project setup for launchpad
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DNS,
  integrations: [new Integrations.BrowserTracing()],

  release: `launchpad-ui@${process.env.REACT_APP_VERSION}`,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  enabled: false,
  environment: process.env.REACT_APP_ENVIRONMENT_PREFIX,
});

function App() {
  return (
    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
      <ChakraProvider theme={customTheme}>
        <ConnectivityMonitor>
          <CustomApolloProvider>
            <Router>
              <AuthManager>
                <QueryParamProvider adapter={ReactRouter6Adapter}>
                  <Routes />
                </QueryParamProvider>
              </AuthManager>
            </Router>
          </CustomApolloProvider>
        </ConnectivityMonitor>
      </ChakraProvider>
    </Sentry.ErrorBoundary>
  );
}

export default App;
